const socialAccounts = [
	{
		id: 0,
		name: 'GitHub',
		profileURL: 'https://github.com/MarinaBaskova/'
	},
	{
		id: 1,
		name: 'LinkedIn',
		profileURL: 'https://www.linkedin.com/in/marina-baskova'
	}
];

export default socialAccounts;
